// src/components/ErrorBoundary/ErrorBoundary.tsx
import React, { Component, ReactNode } from 'react';

interface ErrorBoundaryProps {
  children: ReactNode;
  showMessage: (message: string, type: 'success' | 'error' | 'info') => void;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
    this.handleRetry = this.handleRetry.bind(this);
  }

  /**
   * Updates the state when an error is caught.
   */
  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  /**
   * Logs the error and displays a notification to the user.
   */
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error("ErrorBoundary caught an error:", error, errorInfo);

    const { showMessage } = this.props;
    showMessage('An unexpected error occurred. Please try again.', 'error');
  }

  /**
   * Resets the error state to allow re-rendering of child components.
   */
  handleRetry() {
    console.log("ErrorBoundary: Resetting error state.");
    this.setState({ hasError: false });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={styles.container}>
          <h1 style={styles.heading}>Something went wrong.</h1>
          <button onClick={this.handleRetry} style={styles.button}>
            Try Again
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

/**
 * Inline styles for the fallback UI.
 */
const styles = {
  container: {
    padding: '40px',
    textAlign: 'center' as const,
    backgroundColor: '#f8d7da',
    color: '#721c24',
    borderRadius: '8px',
    margin: '20px',
  },
  heading: {
    fontSize: '24px',
    marginBottom: '20px',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#f5c6cb',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
  },
};

export default ErrorBoundary;
