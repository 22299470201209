// src/components/ErrorBoundary/ErrorBoundaryWrapper.tsx
import React from 'react';
import ErrorBoundary from './ErrorBoundary';
import { useNotification } from '../../context/NotificationContext';

/**
 * Wrapper component that provides the ErrorBoundary with the necessary context.
 */
const ErrorBoundaryWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { showMessage } = useNotification();

  return (
    <ErrorBoundary showMessage={showMessage}>
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryWrapper;
